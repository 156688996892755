export class Approval {
  static setup() {
    $("#approve-selected").click(function() {
      const items_to_approve = [];
      $('input.approval-checkbox:checked').each(function() {
        return items_to_approve.push( $(this).data('approval-id') );
      });
      if (confirm('Are you sure want to approve ' + items_to_approve.length + ' items?' )) {
        const form = $(this).closest('form');
        form.find('.approval-records').val( items_to_approve.join(',') );
        return form.submit();
      }
    });

    Approval.toggleAllCheckBoxes();
  }

  static toggleAllCheckBoxes() {
    $('#approval_actions_approve_all').click(function() {
      if (this.checked) {
        return $(':checkbox:enabled').each(function() {
          this.checked = true;
        });
      } else {
        return $(':checkbox:enabled').each(function() {
          this.checked = false;
        });
      }
    });
    if ($('#approval_actions_approve_all').is(':checked')) {
      return $('.approval-checkbox').attr('checked', true);
    } else {
      return $('.approval-checkbox').attr('checked', false);
    }
  };

}
