import { InputUtilities } from './input_utilities';

export class ServiceLogs {
  static setup() {
    ServiceLogs.toggleOtherActivity();
    ServiceLogs.togglePACount();
    ServiceLogs.toggleTrainingName();
    ServiceLogs.toggleCFTOutcomeOther();
    ServiceLogs.toggleCFTOutcome();
    ServiceLogs.toggleResourceOutcome();
    ServiceLogs.toggleOtherResource();

    $('#service_log_service_activity_id').on('change', function() {
      ServiceLogs.toggleOtherActivity();
      ServiceLogs.togglePACount();
      ServiceLogs.toggleTrainingName();
      ServiceLogs.toggleCFTOutcome();
      ServiceLogs.toggleResourceOutcome();
    });

    $('#service_log_cft_outcome_id').on('change', function() {
      ServiceLogs.toggleCFTOutcomeOther();
    });

    $('#service_log_resource_outcome_id').on('change', function() {
      ServiceLogs.toggleOtherResource();
    });
  }

  static toggleOtherActivity() {
    if ($('#service_log_service_activity_id option:selected').data('other')) {
      InputUtilities.enableField('#service_log_other_activity');
    } else {
      InputUtilities.disableField('#service_log_other_activity');
    }
  }

  static toggleOtherResource() {
    if ($('#service_log_resource_outcome_id option:selected').data('other')) {
      InputUtilities.enableField('#service_log_other_resource');
    } else {
      InputUtilities.disableField('#service_log_other_resource');
    }
  }

  static togglePACount() {
    if ($('#service_log_service_activity_id option:selected').data('pa-count')) {
      InputUtilities.enableField('#service_log_pa_count');
    } else {
      InputUtilities.disableField('#service_log_pa_count');
    }
  }

  static toggleTrainingName() {
    if ($('#service_log_service_activity_id option:selected').data('training')) {
      InputUtilities.enableField('#service_log_training_name');
    } else {
      InputUtilities.disableField('#service_log_training_name');
    }
  }

  static toggleCFTOutcomeOther() {
    if ($('#service_log_cft_outcome_id option:selected').data('other') && ServiceLogs.isShowCFTOutcome()) {
      InputUtilities.enableField('#service_log_cft_outcome_other');
    } else {
      InputUtilities.disableField('#service_log_cft_outcome_other');
    }
  }

  static toggleCFTOutcome() {
    if (ServiceLogs.isShowCFTOutcome()) {
      InputUtilities.enableField('#service_log_cft_outcome_id');
      ServiceLogs.toggleCFTOutcomeOther();
    } else {
      InputUtilities.disableField('#service_log_cft_outcome_id');
    }
  }

  static toggleResourceOutcome() {
    if (ServiceLogs.isShowResourceOutcome()) {
      InputUtilities.enableField('#service_log_resource_outcome_id');
      InputUtilities.enableField('#service_log_name_of_organization_visited');
    } else {
      InputUtilities.disableField('#service_log_resource_outcome_id');
      InputUtilities.disableField('#service_log_name_of_organization_visited');
    }
  }

  static isShowResourceOutcome() {
    return $('#service_log_service_activity_id option:selected').data('require-resource-outcome');
  }

  static isShowCFTOutcome() {
    return $('#service_log_service_activity_id option:selected').data('require-cft-outcome');
  }
}
