import { InputUtilities } from './input_utilities';

export class CaseNote {
    static setup() {
        this.toggleNoteTypeFields();

        $('#case_note_note_type').on('change', () => CaseNote.toggleNoteTypeFields());
        $('#case_note_contacted_other').on('change', () => CaseNote.toggleContactedOtherName());
        $('#case_note_location_id').on('change', () => CaseNote.toggleLocationFields());
        $('#case_note_contacted_social_worker').on('change', () => CaseNote.toggleContactedSocialWorker());
    }

    static toggleLocationFields() {
        const selectedOption = $('#case_note_location_id option:selected');
        this.toggleLocationOther(selectedOption);
        this.toggleTravelTime(selectedOption);
    }

    static toggleLocationOther(selected_option) {
        if (selected_option.data('other-required')) {
            InputUtilities.enableField('#case_note_location_other');
        } else {
            InputUtilities.disableField('#case_note_location_other');
        }
    }

    static toggleTravelTime(selected_option) {
        if (selected_option.data('travel-applicable')) {
            InputUtilities.enableField('#case_note_travel_time');
        } else {
            InputUtilities.disableField('#case_note_travel_time');
        }
    }

    static toggleNoteTypeFields() {
        this.populateNoteLineCategories();
        this.populateNoteLineTopics();
        let note_type = $('#case_note_note_type').val();
        if (note_type === 'general') {
            InputUtilities.disableField('#case_note_contacted_parent');
            InputUtilities.disableField('#case_note_contacted_social_worker');
            InputUtilities.disableField('#case_note_contacted_other');
            InputUtilities.disableField('#case_note_next_appointment');
        } else {
            InputUtilities.enableField('#case_note_contacted_parent');
            InputUtilities.enableField('#case_note_contacted_social_worker');
            InputUtilities.enableField('#case_note_contacted_other');
            InputUtilities.enableField('#case_note_next_appointment');
        }

        if(note_type === 'contacted') {
            InputUtilities.enableField('#case_note_resources');
        } else {
            InputUtilities.disableField('#case_note_resources');
        }

        if(note_type === 'contact_attempted') {
            InputUtilities.enableField('#case_note_appointment_cancelled');
        } else {
            InputUtilities.disableField('#case_note_appointment_cancelled');
        }

        this.toggleContactedOtherName();
        this.toggleLocationFields();
        this.toggleContactedSocialWorker();
    }

    static populateNoteLineCategories() {
        const selectedOption = $('#case_note_note_type option:selected');
        const categories = selectedOption.data('categories');
        if(categories !== undefined) {

            let options = categories.map ((cat) => {
                return `<option value="${cat[1]}">${cat[0]}</option>`;
            });
            options.unshift('<option></option>');
            $('#case_note_case_note_lines_case_note_category_id').html(options.join(' '));
        }
    }

    static populateNoteLineTopics() {
        const selectedOption = $('#case_note_note_type option:selected');
        const topics = selectedOption.data('topics');
        if(topics !== undefined) {

            let options = topics.map ((topic) => {
                return `<option value="${topic[1]}">${topic[0]}</option>`;
            });
            options.unshift('<option></option>');
            $('#case_note_case_note_lines_case_note_topic_id').html(options.join(' '));
        }
    }

    static toggleContactedOtherName() {
        if ($('#case_note_contacted_other').prop('checked')) {
            InputUtilities.enableField('#case_note_contacted_other_name');
        } else {
            InputUtilities.disableField('#case_note_contacted_other_name');
        }
    }

    static toggleContactedSocialWorker() {
        const contactedSocialWorkerCheckbox = $('#case_note_contacted_social_worker');
        if (contactedSocialWorkerCheckbox.prop('checked')) {
            InputUtilities.enableField('#case_note_contacted_social_worker_name');
        } else {
            InputUtilities.disableField('#case_note_contacted_social_worker_name');
        }
    }

}
