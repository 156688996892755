export class CaseNoteLines {
  static setup() {
    let noTableEntry;
    $('#case_note_case_note_lines_case_note_category_id, #case_note_case_note_lines_case_note_topic_id').on('change', function() {
      const categoryName  = $('#case_note_case_note_lines_case_note_category_id option:selected').text();
      const categoryNameHidden  = $('#case_note_case_note_lines_case_note_category_id').val();
      const topicName     = $('#case_note_case_note_lines_case_note_topic_id option:selected').text();
      const categoryId    = $('#case_note_case_note_lines_case_note_category_id option:selected').val();
      const topicId       = $('#case_note_case_note_lines_case_note_topic_id option:selected').val();
      const uniqueTableId = "case_note_line_" + categoryId + '_' + topicId;

      if (CaseNoteLines.selectsAreFilled(categoryName, topicName) && noTableEntry(uniqueTableId)) {
        $('#contact-details table').append("<tr><td><i class='fa fa-times text-danger detail-delete'></i></td><td>" + categoryName + "</td><td>" + topicName + "</td><td class='d-none'><input type='text' name='case_note_lines[]' id=" + uniqueTableId + " value='[" + categoryId + ", " + topicId + "]'></tr>");
      } else if( CaseNoteLines.selectsAreFilled( categoryNameHidden, topicName )) {
        $('#contact-details table').append("<tr><td><i class='fa fa-times text-danger detail-delete'></i></td><td>" + topicName + "</td><td class='d-none'><input type='text' name='case_note_lines[]' id=" + uniqueTableId + " value='[" + categoryNameHidden + ", " + topicId + "]'></tr>");
      }

      if (CaseNoteLines.selectsAreFilled(categoryName, topicName)) {
        return $('#case_note_case_note_lines_case_note_category_id, #case_note_case_note_lines_case_note_topic_id').prop('selectedIndex',0);
      }
    });

    $('#contact-details table').on('click', '.detail-delete', function() {
      return $(this).closest('tr').remove();
    });

    return noTableEntry = uniqueTableId => !document.getElementById(uniqueTableId);
  }

  static selectsAreFilled(categoryName, topicName) {
    if (categoryName.length && topicName.length) { return true; } else { return false; }
  };
}
