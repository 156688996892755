export class ClosureReason {
  static setup() {
    ClosureReason.toggleClosureReason();
    return $('#closure_closure_reason_id').on('change', () => ClosureReason.toggleClosureReason());
  }

  static toggleClosureReason() {
    const closure_fields = $('.closure_closure_reason_other').children();
    if ($('#closure_closure_reason_id option:selected').data('other')) {
      return closure_fields.removeClass('d-none');
    } else {
      closure_fields.addClass('d-none');
      return $('#closure_closure_reason_other').val('');
    }
  };
}
