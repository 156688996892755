export class ChatReferral {
  static setup() {
    const check_box = $("#chat_referral_assigned");
    const div_reason = $("#div_denial_reason");
    const div_outcome = $("#div_outcome");
    const div_advocate = $("#div_advocate");
    div_outcome.hide();
    div_advocate.hide();
    if (check_box.prop("checked")) {
      div_reason.hide();
      div_outcome.show();
      div_advocate.show();
    }
    return check_box.change(function() {
      if ($(this).prop("checked")) {
        div_reason.hide();
        div_outcome.show();
        return div_advocate.show();
      } else {
        div_reason.show();
        div_outcome.hide();
        return div_advocate.hide();
      }
    });
  }
}
