import { InputUtilities } from './input_utilities';

export class CaseFile {
  static setup() {
    this.toggleSocialWorkerLabels();

    $('#case_file_path').on('change', () => CaseFile.toggleSocialWorkerLabels());
  }

  static toggleSocialWorkerLabels() {
    let path_value= $('#case_file_path').val();

    if (path_value == null) {
      return;
    }

    if (path_value === 'community') {
      InputUtilities.changeLabel('#case_file_social_worker_name', 'Provider Name');
      InputUtilities.changeLabel('#case_file_social_worker_email', 'Provider Email');
      InputUtilities.changeLabel('#case_file_social_worker_phone', 'Provider Phone');
    } else {
      InputUtilities.changeLabel('#case_file_social_worker_name', 'Social Worker Name');
      InputUtilities.changeLabel('#case_file_social_worker_email', 'Social Worker Email');
      InputUtilities.changeLabel('#case_file_social_worker_phone', 'Social Worker Phone');
    }
  }
}
