export class InputUtilities {
  static enableField(id) {
    this.toggleReadOnly(id, false, false);
  }

  static disableField(id) {
    this.toggleReadOnly(id, true, true);
  }

  static changeLabel(id, value) {
    let field = $(id);
    field.siblings('label').text(value);
  }

  static toggleReadOnly(id, readonly, resetValue) {
    const input = $(id);
    if(input.length) {
      const tag = input[0].tagName;
      let requiredSpan = input.closest('.form-group').find('label span[title=required]');

      if(tag === 'SELECT') {
        if(readonly) {
          input.attr('readonly', true);
          requiredSpan.hide();
          if(resetValue) {
            $(`${id} option`).attr('disabled', 'disabled');
            $(`${id} option[value='']`).removeAttr('disabled');
            input.val('');
          } else {
            $(`${id} option:not(:selected)`).attr('disabled', 'disabled');
          }
        } else {
          $(`${id} option`).removeAttr('disabled');
          input.attr('readonly', false);
          requiredSpan.show();
        }
      }
      else if(tag === 'INPUT' && input[0].type === 'checkbox') {
        if(readonly) {
          if(resetValue) {
            input.prop('checked', false);
            input.prev().val('0');
          }
          input.prop('disabled', true);
        } else {
          input.prop('disabled', false);
        }
      }
      else {
        input.attr('readonly', readonly);
        if(readonly) {
          requiredSpan.hide();
        } else {
          requiredSpan.show();
        }
        if(resetValue) {
          input.val('');
        }
      }
    }
  }
}
